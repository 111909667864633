import React, { useEffect, useState } from 'react'
import { Container, Card, Button, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row } from 'react-bootstrap'
import { OPEN_MODAL } from 'Store/Actions/Modal/Action'
import { api } from 'Interceptor'
import { toastMessage } from 'Helper/Toaster'
import Header from 'components/Headers/Header'
import BootstrapTable from 'react-bootstrap-table-next'
import { announcementTableColum } from 'Helper/TableColumns'
import Tidbitmodal from 'components/Modal.js/Tidbitmodal'
const Announcement = () => {
    const [announcement, setAnnouncements] = useState([])
    const { modalReducer } = useSelector(state => state)
    const getDomText = (text) => {
        if (!text) {
            return;
        }
        const plainText = document.createElement('div')
        plainText.innerHTML = `${text}`
        return plainText.innerText.substring(0, 80)
    }
    const getAllAnnouncement = () => {
        api.headers().method('get', 'contents')
            .then(response => {
                response.data.contents.map((content, id) => {
                    content.content.markup = <span>{getDomText(content.content.markup)}...</span>
                    content.actions = <UncontrolledDropdown>
                        <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                        >
                            <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'edit-announcement', id: content?._id }))}>Edit Tidbit</DropdownItem>
                            <DropdownItem onClick={() => deleteAnnouncement(content?._id)}>Delete Tidbit</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown >
                    return content
                })
                setAnnouncements(response.data.contents)
            })
            .catch(error => console.log(error.response))
    }
    useEffect(() => {
        getAllAnnouncement()
    }, [modalReducer])
    const deleteAnnouncement = (id) => {
        api.headers().method('post', `content/delete/${id}`)
            .then(response => {
                toastMessage.success("Tidbit successfully deleted")
                getAllAnnouncement()

            })
            .catch(error => console.log(error.response))
    }
    const dispatch = useDispatch()
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <div className="col">
                        <Card className="bg-default shadow ">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                                <h3 className="text-white mb-0">TIDBIT</h3>
                                <Button color="primary" onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-announcement' }))}>Create Tidbit</Button>
                            </CardHeader>
                            <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={announcement} columns={announcementTableColum} />

                        </Card>
                    </div>
                </Row>
            </Container>
            <Tidbitmodal />
        </div>
    )
}

export default Announcement
