import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GloabalModal from './GlobalModal'
const CreateCategoryModal = () => {
  const { modalReducer } = useSelector(state => state)
  const [category, setCategory] = useState({})
  const submitCategory = async (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let body = {}
    for (var pair of formData.entries()) {
      body = {
        ...body,
        [pair[0]]: pair[1]
      }
    }
    if (modalReducer.typeOfmodal === "edit-category") {
      body = {
        ...body,
        content_category_id: category._id
      }
      const { data } = await api.method('post', 'content/category/manage', body)
      toastMessage.success("Category updated")
      return
    }
    const { data } = await api.method('post', 'content/category/manage', body)
    if (data) {
      toastMessage.success("Category created ")
      return
    }
    toastMessage.failure("there is an error in creating catogory please check")
  }
  useEffect(async () => {
    if (modalReducer.typeOfmodal === 'edit-category') {
      const { data: { content_category } } = await api.method('get', `content/category/${modalReducer.id}`)
      setCategory(content_category)
    }
  }, [modalReducer.id]);

  return (
    <>
      <GloabalModal heading={modalReducer.typeOfmodal === 'edit-category' ? "Edit Category" : "Create Category"}>
        <form onSubmit={submitCategory}>
          <label htmlFor="name">Category Title </label>
          <input required type="text" className='form-control' id='name' name='title' defaultValue={modalReducer.typeOfmodal === 'edit-category' ? category?.title : ""} />
          <Button className='my-3' type="submit">Submit</Button>
        </form>
      </GloabalModal>
    </>
  )
}

export default CreateCategoryModal;
