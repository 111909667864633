
import {
    Card,
    CardHeader,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { api } from "../../Interceptor";
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from "Helper/Functions";
import BootstrapTable from "react-bootstrap-table-next";
import { eventtablecolumns } from "Helper/TableColumns";
import Header from "components/Headers/Header";
import PropertyModal from "components/Modal.js/PropertyModal";
import { OPEN_MODAL } from "Store/Actions/Modal/Action";
import { formatNumbers } from "Helper/Functions";
const Properties = () => {
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(false)
    const { modalReducer } = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(() => {
        getProperties();
    }, [modalReducer]);


    const getProperties = () => {
        setLoading(true)
        api.headers().method('get', 'properties')
            .then(response => {
                removeDuplicatesFromProperties(response.data.properties, NotEquals)

                response.data.properties.map((property, id) => {
                    property['S.No'] = id + 1
                    eventtablecolumns.forEach(element => {
                        if (property[element.dataField] != null || property[element.dataField] != undefined) {
                            property[element.dataField] = element.format ? formatNumbers(property[element.dataField], element.format) : property[element.dataField];
                        } else {
                            property[element.dataField] = "---";
                        }
                    });

                    property.address = `${property.address.substring(0, 15)}`
                    property.project_type = <span className="badge badge-info text-light">{property.project_type}</span>
                    property.view_property = <UncontrolledDropdown>
                        <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                        >
                            <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: property?._id, typeOfmodal: 'view-property' }))}>View Property</DropdownItem>
                            <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: property?._id, typeOfmodal: 'upload-image' }))}>Upload Images</DropdownItem>
                            <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: property?._id, typeOfmodal: 'delete-property' }))}>Delete Property</DropdownItem>
                            <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: property?.project_name, typeOfmodal: 'view-varients' }))}>View Varients</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    return property

                })
                const UniquesName = []
                response.data.properties.forEach(x => {
                    UniquesName.some(y => {
                        if (y.project_name !== x.project_name) {
                            UniquesName.push(x)
                        }
                    })

                })
                setProperties(response.data.properties)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                showNotification(error);
            })
    }
    function removeDuplicatesFromProperties(arr, equals) {
        var props = arr.slice(0);
        var i, len, val;
        arr.length = 0;

        for (i = 0, len = props.length; i < len; ++i) {
            val = props[i];
            if (!arr.some(function (item) { return equals(item, val); })) {
                arr.push(val);
            }
        }
    }

    function NotEquals(prop1, prop2) {
        return prop1.project_name === prop2.project_name;
    }
    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <div className="col">
                        <Card className="bg-default shadow ">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                                <h3 className="text-white mb-0">Properties</h3>
                            </CardHeader>
                            {loading && <i className="fas fa-spinner fa-pulse"></i>}
                            <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={properties} columns={eventtablecolumns} noDataIndication='No Proprty Found' />

                        </Card>
                    </div>
                </Row>
            </Container>
            <PropertyModal />
        </>
    );
};

export default Properties;
