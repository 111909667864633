import UploadPropertyImages from 'components/UploadPropertyImages/UploadPropertyImages'
import { propertiesProps } from 'Helper/Constant'
import { toastMessage } from 'Helper/Toaster'
import { api } from 'Interceptor'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_MODAL } from 'Store/Actions/Modal/Action'
import GloabalModal from './GlobalModal'
const PropertyModal = () => {
    const [varientsDetails, setVarientsDetails] = useState([])
    const [files, setFiles] = useState([]);
    const [uploadedImages, modifyUploadedImages] = useState([]);
    const { modalReducer: { id, typeOfmodal } } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const [properties, setProperty] = useState({})
    const dispatch = useDispatch()
    const viewProperty = useCallback(
        async () => {
            setLoading(true)
            const { data: { property } } = await api.headers().method('get', `property/${id}`)
            setProperty(property)
            setLoading(false)
        },
        [id],
    );
    const closeModal = () => {
        dispatch(CLOSE_MODAL({ isOpen: false }))
    }
    const uploadImages = async () => {
        const formData = new FormData()
        const x = uploadedImages.map((imagePath) => imagePath.split('/').reverse()[0])
        formData.append('images_array', JSON.stringify(x))
        files.map(f => { formData.append('property_images', f); return f });
        const { data } = await api.method('post', `/property/upload/images/${id}`, formData)
        if (data) {
            toastMessage.success("Property images uploaded successfully")
            closeModal()
        }

    }
    const deletProperty = async () => {
        const del = await api.headers().method('post', `property/delete/${id}`)
        toastMessage.success("Property deleted successfully")
        closeModal()
    }
    useEffect(async () => {
        if (typeOfmodal === 'view-property') {
            viewProperty()
        }
        if (typeOfmodal === 'upload-image') {
            const { data: { property: { property_images } } } = await api.headers().method('get', `property/${id}`)
            modifyUploadedImages(property_images)
        }
        if (typeOfmodal === 'view-varients') {
            setLoading(true)
            const { data: { properties } } = await api.method('get', `properties?project_name=${id}`)
            setVarientsDetails(properties)
            setLoading(false)

        }
    }, [id])
    if (typeOfmodal === 'view-property') {
        return (
            <GloabalModal heading={` ${'Property Details'} `} size="lg">
                {!loading ?
                    <>

                        <Table
                            className="align-items-center table-light table-flush"
                            responsive
                        >
                            <thead className="thead-dark">
                                <tr>
                                    <th>Name</th>
                                    <th>Value</th>

                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(
                                    propertiesProps.map(({ name, value }) => {
                                        if (properties[`${name}`]) {
                                            return (
                                                <>
                                                    <tr>
                                                        <th><h4>{value}</h4></th>
                                                        <th><p>{properties[`${name}`]}</p></th>
                                                    </tr>
                                                </>
                                            )
                                        }
                                        return null
                                    })
                                )}
                                <tr>
                                    <th>
                                        <h4>Facilities</h4>
                                    </th>
                                    <th>
                                        {
                                            properties.facilities && properties.facilities.map((facility) => {
                                                return (
                                                    <span className='badge badge-secondary text-dark'>
                                                        {facility}
                                                    </span>
                                                )
                                            })
                                        }
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <h4>Developer</h4>
                                    </th>
                                    <th>
                                        {
                                            properties.developer && properties.developer.map((developer) => {
                                                return (
                                                    <span className='badge badge-secondary text-dark'>
                                                        {developer}
                                                    </span>
                                                )
                                            })
                                        }
                                    </th>
                                </tr>


                            </tbody>
                        </Table>



                    </>
                    :
                    <i className="fas fa-spinner"></i>
                }
            </GloabalModal>
        )
    }
    if (typeOfmodal === 'upload-image') {
        return (
            <GloabalModal size="lg" heading="Upload Property Images" >
                <UploadPropertyImages files={files} setFiles={setFiles} uploadedImages={uploadedImages} modifyUploadedImages={modifyUploadedImages} />
                <Button onClick={uploadImages}>Upload Images</Button>
            </GloabalModal>
        )
    }
    if (typeOfmodal === 'view-varients') {
        return (
            <>
                {

                    <GloabalModal size="lg" heading={`${id}'s Varients`} >
                        {loading ?

                            <div className='text-center'>
                                <i className="fas fa-spinner " style={{ fontSize: '36px' }}></i>

                            </div>
                            :
                            <Table
                                className="align-items-center table-light table-flush"
                                responsive
                            >
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Min Price</th>
                                        <th>Max Price</th>
                                        <th>Unit Type</th>
                                        <th>Unit By Type</th>
                                        <th>No Of Bedrooms</th>
                                        <th>No Of Bathrooms</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {React.Children.toArray(
                                        varientsDetails.map((varients) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <th>{varients.min_price ? varients.min_price : "Call To Enquire"}</th>
                                                        <th>{varients.max_price ? varients.max_price : "Call To Enquire"}</th>
                                                        <th>{varients.unit_type ? varients.unit_type : "Call To Enquire"}</th>
                                                        <th>{varients.units_by_type ? varients.units_by_type : "Call To Enquire"}</th>
                                                        <th>{varients.bedrooms ? varients.bedrooms : "Call To Enquire"}</th>
                                                        <th>{varients.bathrooms ? varients.bathrooms : "Call To Enquire"}</th>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    )}


                                </tbody>
                            </Table>}
                    </GloabalModal>
                }
            </>
        )
    }
    return (
        <GloabalModal heading="Delete Property" >
            <h2>Would you like to delete this property</h2>
            <Button variant="primary" onClick={deletProperty}>Delete</Button>
            <Button variant="light" onClick={closeModal}>Close</Button>
        </GloabalModal>
    )

}

export default PropertyModal
