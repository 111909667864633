import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Cloud from '../../assets/img/upload.png'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { bytesToSize } from 'Helper/Functions';
const UploadFile = (props) => {
    const [percentage, setPercentage] = useState({})
    const {
        getRootProps,
        getInputProps,
        open,
        acceptedFiles

    } = useDropzone({
        accept: '.xlsx ,.xls',
        maxFiles: 1,
        noClick: true,
        noKeyboard: true
    });
    useEffect(() => {
        let fileUploaded = acceptedFiles.length
        if (fileUploaded > 0) {
            const formData = new FormData()
            formData.append("property_file", acceptedFiles[0])
            const options = {
                onUploadProgress: (propgressEvent) => {
                    console.log(propgressEvent)
                    const { loaded, total } = propgressEvent
                    setPercentage({
                        successRatio: Math.floor((loaded * 100) / total),
                        loadingType: 'info',
                    })
                    fileUploaded = 0
                }
            }
            api.headers().method('post', 'property/upload', formData, options)
                .then((response) => {
                    setPercentage({
                        ...percentage,
                        isSuccess: true,
                        successRatio: 100,
                        loadingType: 'success',
                    })
                    toastMessage.success("Properties extraction successfully completed!")

                })
                .catch((error) => {
                    toastMessage.failure("Properties couldn't be fetched!")
                    setPercentage({
                        ...percentage,
                        isSuccess: false,
                        successRatio: 100,
                        loadingType: 'danger',
                    })
                })
        }
    }, [acceptedFiles])
    return (
        <div className="container">

            <div>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <img src={Cloud} />
                    <p>Drag & Drop To Upload File</p>
                    <Button varient="primary" type="button" onClick={open}>
                        Browse Excel File
                    </Button>
                </div>
                <aside>
                    {
                        acceptedFiles.map(file => (
                            <span key={file.path}>
                                {file.path} - {bytesToSize(file.size)}
                            </span>
                        ))}
                </aside>
                <div className="w-25 mt-4 m-auto">
                    {acceptedFiles.length > 0 ? <ProgressBar animated striped variant={percentage.loadingType} now={percentage.successRatio} /> : null}
                </div>

            </div>
        </div>
    );
}

export default UploadFile