import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import CreateCategoryModal from '../../components/Modal.js/CreateCategoryModal'
import { toastMessage } from 'Helper/Toaster';

const Category = () => {
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const { modalReducer } = useSelector(state => state)
  async function getData() {
    const { data: { content_categories } } = await api.method('get', 'content/categories/all')
    content_categories.map((cat, id) => {
      cat['s.no'] = id + 1
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'edit-category', id: cat._id }))}>Edit Category</DropdownItem>
          <DropdownItem onClick={async () => {
            const { data } = await api.method('post', `content/category/delete/${cat._id}`)
            if (data) {
              toastMessage.success("Category updated")
              getData()
              return
            }
            toastMessage.failure("Error in deleting category")

          }}>Delete Category</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(content_categories)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'title',
      text: 'Title',
    },
    {
      dataField: 'actions',
      text: 'Actions',
    },

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">TIDBIT CATEGORY</h3>
                <Button color="primary"
                  onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-category' }))}
                >Create Category</Button>
              </CardHeader>
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns} />

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default Category;
