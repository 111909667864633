import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_MODAL } from 'Store/Actions/Modal/Action'

const GloabalModal = ({ heading, children, additionalClasses, size }) => {
    const { modalReducer } = useSelector(state => state)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(CLOSE_MODAL({ isOpen: false, id: null }))
    }
    return (
        <div>
            <Modal contentClassName={additionalClasses} show={modalReducer.isOpen} centered onHide={handleClose} size={`${size}`}>
                <Modal.Header>
                    <Modal.Title>
                        <h2>{heading}</h2>
                    </Modal.Title>
                    <i className="fas fa-times text-danger" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default GloabalModal