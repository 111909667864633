import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const UploadPropertyImages = ({ files, setFiles, uploadedImages, modifyUploadedImages }) => {
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 5,
    accept: 'image/jpeg, image/png, image/jpg ,image/svg, image/WebP',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);
  const thumbs = files.map((file, id) => (
    <div key={id} style={thumb} key={file.name}>
      <div style={thumbInner} className='position-relative images'>
        <img
          src={file.preview}
          style={img}
        />
        <i className="fas fa-times position-absolute" onClick={() => {
          const updatedFiles = files.filter((newFile, newFileId) => {
            if (newFileId !== id) {
              return newFile
            }
          })
          setFiles(updatedFiles)
        }}></i>
      </div>
    </div>
  ));

  return (
    <>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop property images  here, or click to select images</p>
          <em>(5 files are the maximum number of images you can drop here)</em>
        </div>
        <aside style={thumbsContainer}>
          {thumbs}
        </aside>
        <h3>Upload Images</h3>
        {React.Children.toArray(
          uploadedImages.map((imgPreview, id) => {
            return (
              <div style={thumb}>
                <div style={thumbInner} className='position-relative images'>
                  <img style={img} src={imgPreview} alt="" />
                  <i className="fas fa-times position-absolute" onClick={() => {
                    const x = uploadedImages.filter((newFile, newFileId) => {
                      if (newFileId !== id) {
                        return newFile
                      }
                    })
                    modifyUploadedImages(x)
                  }}></i>
                </div>
              </div>
            )
          })
        )}
      </section>
    </>
  )

};

export default UploadPropertyImages;
