import { toastMessage } from 'Helper/Toaster'
import { api } from 'Interceptor'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import GloabalModal from './GlobalModal'
import { CLOSE_MODAL } from 'Store/Actions/Modal/Action'

const Tidbitmodal = (props) => {
    const { modalReducer } = useSelector(state => state)
    const dispatch = useDispatch()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [error, setError] = useState('')
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    const [allCategory, setAllCategory] = useState([])
    const closeModal = () => {
        dispatch(CLOSE_MODAL({ isOpen: false }))
    }
    const updateAnnouncement = () => {
        const announcement = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const requestBody = {
            content_type: 'announcement',
            content_id: modalReducer?.id,
            content_category: category
        }
        requestBody.title = title;
        requestBody.content = { markup: announcement }

        api.headers().method('post', 'content/manage', requestBody)
            .then(response => {
                toastMessage.success('Tidbit updated successfully')
                closeModal()
            })
            .catch(error => toastMessage.failure('Tidbit update failed'))
    }
    const createAnnouncement = () => {
        const announcement = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (!title) {
            setError("Tidbit Title is required")
            return false
        }
        if (!category) {
            alert("please select tidbit category")
            return
        }
        const requestBody = {
            content_type: 'announcement',
            title: title,
            content_category: category,
            content: {
                markup: announcement
            }

        }
        api.headers().method('post', 'content/manage', requestBody)
            .then(response => {
                toastMessage.success("Announcement is created")
                closeModal()
            })
            .catch(error => toastMessage.failure("There is an error in creating TIDBIT"))
    }
    const updateHTMLToEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentLocalState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorLocalState = EditorState.createWithContent(contentLocalState);
            setEditorState(editorLocalState);
        }
    }
    useEffect(() => {
        if (!modalReducer?.id) {
            return;
        }

        api.headers().method('get', `content/${modalReducer?.id}`).then((response) => {
            setTitle(response.data.content.title)
            setCategory(response.data.content.content_category)
            updateHTMLToEditor(response.data.content.content.markup);
        }).catch(({ response }) => console.log(response))


    }, [modalReducer?.id])
    useEffect(() => {
        api.method('get', 'content/categories/all')
            .then(response => setAllCategory(response.data.content_categories))
            .catch(({ response }) => console.log(response))
    }, [modalReducer?.id]);

    const updateEditorState = (state) => {
        setEditorState(state);
    }

    if (['create-announcement', 'edit-announcement'].includes(modalReducer.typeOfmodal)) {
        return (
            <div>
                <GloabalModal size="lg" heading={modalReducer.typeOfmodal === 'create-announcement' ? 'Create New Tidbit' : 'Edit Announcement'}>
                    <Form>
                        <Row>
                            <Form.Group className="my-2" as={Col} lg={12}>
                                <Form.Label>Tidbit Category</Form.Label>
                                <select
                                    value={category}
                                    className="form-control"
                                    aria-label="Default select example"
                                    onChange={({ target }) => {
                                        setCategory(target.value)
                                    }}>
                                    {
                                        React.Children.toArray(
                                            allCategory.map(({ _id, title }) => {
                                                return (
                                                    <>
                                                        <option hidden>Select Category</option>
                                                        <option value={_id}>{title}</option>
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                </select>
                            </Form.Group>
                            <Form.Group className="my-2" as={Col} lg={12}>
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" value={title} onChange={(e) => {
                                    setTitle(e.target.value)
                                    setError('')
                                }} />
                                {error && <p className='text-danger'>{error}</p>}
                            </Form.Group>
                            <Form.Group className="my-2" as={Col} lg={12}>
                                <Form.Label>Tidbit</Form.Label>
                                <Card className="bg-default shadow ">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName text-darel"
                                        onEditorStateChange={updateEditorState}
                                        stripPastedStyles={true}
                                    />
                                </Card>
                            </Form.Group>
                        </Row>
                        <div className='text-center'>
                            <Button onClick={modalReducer.typeOfmodal === 'create-announcement' ? createAnnouncement : updateAnnouncement}>{modalReducer.typeOfmodal === 'create-announcement' ? 'Submit' : 'Update'}</Button>
                        </div>
                    </Form>
                </GloabalModal>
            </div>
        )
    }
    return null
}

export default Tidbitmodal
