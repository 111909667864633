import Header from 'components/Headers/Header';
import UploadFile from 'components/UploadFile/UploadFile';
import React from 'react'
import { Row, Container, Card } from 'react-bootstrap';

const UploadProperties = () => {
    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <div className="col">
                        <Card className="upload-file-contianer">
                            <UploadFile />
                        </Card>
                    </div>
                </Row>
            </Container>

        </>
    );
};

export default UploadProperties;
