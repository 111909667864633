
import Login from "views/Pages/Login.js";
import Announcement from "./views/Pages/Announcement";
import UploadProperties from "./views/Pages/UploadProperties";
import Properties from "./views/Pages/Properties";
import Category from "views/Pages/Category";

var routes = [
  {
    path: "/properties",
    name: "Properties",
    icon: "fas fa-info text-red",
    component: Properties,
    layout: "/admin",
  },
  {
    path: "/upload-properties",
    name: "Upload Properties",
    icon: "fas fa-upload text-red",
    component: UploadProperties,
    layout: "/admin",
  },
  {
    path: "/tidbit",
    name: "Tidbit",
    icon: "fa fa-bullhorn text-red",
    component: Announcement,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Tidbit Category",
    icon: "fa fa-bullhorn text-red",
    component: Category,
    layout: "/admin",
  },


  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    hideInSidebar: true,
    layout: "/auth",
  },


];
export default routes;
