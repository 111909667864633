import { api } from "Interceptor"
import { useState } from "react"
import { useHistory } from "react-router"
import { toastMessage } from "./Toaster"
const PostRequest = () => {
    const history = useHistory()
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)
    const LoginAsSuperAdmin = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.target)
        let bodyObject = {}
        for (var pair of formData.entries()) {
            if (!pair[1]) {
                setError({
                    [pair[0]]: `${pair[0]} is required`
                })
                return false
            }

            bodyObject[`${pair[0]}`] = pair[1]
        }
        setLoading(true)
        try {
            api.method('post', 'auth/login', bodyObject)
                .then(response => {
                    setLoading(false)
                    localStorage.setItem('superadmin-session', JSON.stringify(response.data))
                    history.push('/admin/properties')
                    toastMessage.success('User has been logged in successfully!')

                })
                .catch(error => {
                    setLoading(false)
                    if (error.response || error) {
                        toastMessage.failure('Login failed!')
                    }
                })
        } catch (error) {
            toastMessage.failure('Internal Server Issue!')

        }
    }

    const changeInputValue = ({ target }) => {
        const { name, value } = target
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value) {
            switch (name) {
                case 'email':
                    emailRegex.test(value) ? setError({
                        [name]: ``

                    }) : setError({
                        [name]: `${name} is not in the correct format`
                    })
                    break;
                case 'password':
                    setError({
                        [name]: ''
                    })
                default:
                    break;
            }
        } else {
            setError({
                [name]: ''
            })
        }
    }

    return [error, changeInputValue, LoginAsSuperAdmin, loading]
}

export default PostRequest
