export const eventtablecolumns = [
    {
        dataField: "S.No",
        text: '#'
    },
    {
        dataField: 'project_name',
        text: 'Property Name',
        sort: true
    },

    {
        dataField: 'land_size',
        text: 'Land Size',
        format: 'number',
        sort: true
    },
    {
        dataField: 'address',
        text: 'Address',
    },
    {
        dataField: 'unit_size',
        text: "Unit Size"
    },
    {
        dataField: 'project_type',
        text: 'Property Type',
        sort: true
    },
    {
        dataField: 'view_property',
        text: 'View Property',
    },

]

export const announcementTableColum = [

    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'content_category.title',
        text: 'Category',
    },
    {
        dataField: 'content.markup',
        text: 'content',
    },
    {
        dataField: 'actions',
        text: 'Actions',
    }
]